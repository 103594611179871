@font-face {
  font-family: 'Inter';
  font-weight: 300;
  font-style: normal;
  src: url("../apps/frontend/src/assets/fonts/Inter-Light.ttf");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url("../apps/frontend/src/assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url("../apps/frontend/src/assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url("../apps/frontend/src/assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url("../apps/frontend/src/assets/fonts/Inter-Bold.ttf");
}

@mixin inter-light {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin inter-regular {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin inter-medium {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin inter-semibold {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin inter-bold {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-style: normal;
}
