@import "shared/variables";

.toast {
  width: auto;
  max-width: 100%;
  font-size: .875rem;
  color: $color-primary;
  pointer-events: auto;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid $light-grey;
  box-shadow: $box-shadow;
  border-radius: $border-radius-s;
  position: fixed;
  transform: translate3d(0,0,0);
  z-index: 9999;

  &--top {
    top: calc($header-height + $spacing-m);
    left: 50%;
    transform: translateX(-50%);
  }
  &--top-left {
    top: calc($header-height + $spacing-m);
    left: $spacing-m;
  }
  &--top-right {
    top: calc($header-height + $spacing-m);
    right: $spacing-m;
  }
  &--bottom {
    bottom: $spacing-m;
    left: 50%;
    transform: translateX(-50%);
  }
  &--bottom-left {
    bottom: $spacing-m;
    left: $spacing-m;
  }
  &--bottom-right {
    bottom: $spacing-m;
    right: $spacing-m;
  }
  &--center {
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }

  &--error {
    background-color: $red-background-subtle;
    border-color: $red-border-medium;
    .toast-body {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 26px;
      color: $red-content-primary;
      &:before {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1ZM10 5C10.4142 5 10.75 5.33579 10.75 5.75V7.25C10.75 7.66421 10.4142 8 10 8C9.58579 8 9.25 7.66421 9.25 7.25V5.75C9.25 5.33579 9.58579 5 10 5ZM10 10C10.4142 10 10.75 10.3358 10.75 10.75L10.75 14.25C10.75 14.6642 10.4142 15 10 15C9.58579 15 9.25 14.6642 9.25 14.25L9.25 10.75C9.25 10.3358 9.58579 10 10 10Z' fill='%23C53434'/%3E%3C/svg%3E");
        height: 20px;
        width: 20px;
        left: -.75rem;
      }
    }
  }

  &--success {
    background-color: #FFFFFF;
    .toast-body {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 26px;
      color: $color-primary;
      &:before {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.66699 10.0003C1.66699 14.6027 5.398 18.3337 10.0003 18.3337C14.6027 18.3337 18.3337 14.6027 18.3337 10.0003C18.3337 5.398 14.6027 1.66699 10.0003 1.66699C5.398 1.66699 1.66699 5.398 1.66699 10.0003ZM13.6251 7.10343C13.7002 7.17224 13.7609 7.25516 13.8039 7.34745C13.8469 7.43974 13.8713 7.53959 13.8757 7.64131C13.88 7.74302 13.8644 7.8446 13.8295 7.94026C13.7946 8.03591 13.7412 8.12375 13.6724 8.19878L9.40885 12.8499C9.33813 12.927 9.25254 12.989 9.15723 13.0321C9.06193 13.0752 8.95888 13.0986 8.85429 13.1009C8.74971 13.1031 8.64575 13.0842 8.54868 13.0452C8.45161 13.0062 8.36344 12.9479 8.28947 12.874L6.35149 10.936C6.27745 10.8645 6.21839 10.7789 6.17777 10.6844C6.13714 10.5898 6.11576 10.4881 6.11486 10.3851C6.11397 10.2822 6.13358 10.1801 6.17256 10.0849C6.21153 9.98959 6.26909 9.90304 6.34188 9.83025C6.41467 9.75747 6.50122 9.69991 6.59649 9.66093C6.69175 9.62195 6.79383 9.60234 6.89676 9.60323C6.99969 9.60413 7.10141 9.62551 7.19599 9.66614C7.29057 9.70677 7.3761 9.76582 7.44761 9.83986L8.8135 11.205L12.5298 7.15071C12.5986 7.07568 12.6815 7.01494 12.7738 6.97195C12.8661 6.92897 12.9659 6.90458 13.0677 6.90019C13.1694 6.8958 13.271 6.91149 13.3666 6.94636C13.4623 6.98123 13.5501 7.0346 13.6251 7.10343Z' fill='%231D7C4D'/%3E%3C/svg%3E");
        height: 20px;
        width: 20px;
        left: -.75rem;
      }
    }
  }
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: 9999;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: $spacing-m;
}

.toast-body {
  padding: $spacing-s $spacing-sl;
  word-wrap: break-word;
}
