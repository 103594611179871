@import "shared/variables";
@import 'assets/styles/toast';
@import 'assets/styles/input';

body {
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
  overflow-y: scroll;
  height: 100%;
  overscroll-behavior-y: contain;
}
html {
  height: 100%;
  overscroll-behavior-y: contain;
}

markdown {
  white-space: break-spaces;
  p {
    margin: 0;
  }
}

.w100 {
  width: 100% !important;
  &-mobile {
    width: 100%;

    @include breakpoint(sm) {
      width: auto;
    }
  }
}
.w50 {
  width: 50% !important;
}
.w33 {
  width: calc(100% / 3);
}
.w25 {
  width: 25% !important;
}

.fade {
  transition: opacity .15s linear;
}

.modal-backdrop {
  position: fixed;
  transform: translate3d(0,0,0);
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: white;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.64;
  }
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  padding: 0 50px;
  min-height: calc(100vh - 20px);
  max-width: 600px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
  overflow-x: hidden;
  @include breakpoint('sm', 'max') {
    padding: 0 0.5rem;
    max-width: unset;
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 4px);
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.5rem;
  outline: 0;
  padding: 2px;
  box-shadow: 0px 16px 24px -6px rgba(4, 41, 68, 0.16), 0px 2px 2px -1px rgba(0, 43, 94, 0.04);
}

.modal {
  position: fixed;
  transform: translate3d(0,0,0);
  top: 0;
  left: 0;
  z-index: 1055;
  display: block!important;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.link {
  margin: 0;
  &--text-link {
    color: $blue-action-enabled !important;
    font-size: .875rem;
    line-height: 142.857%;
    @include inter-regular;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      color: $blue-hover;
      text-decoration: none;
    }
  }
}

.errors {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__info-text {
    color: $danger;
    @include inter-light;
    font-size: 0.875rem;
    margin-bottom: 0;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__item {
    color: $danger;
    @include inter-light;
    font-size: 0.75rem;

    &--accept {
      color: $soft-green !important;
    }
  }
}

.estimated {
  display: none;
  align-items: center;
  justify-content: flex-end;
  gap: $spacing-m;
  padding: $spacing-xs 0 0 0;

  @include breakpoint(sm) {
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: $spacing-xs;
    svg, svg-icon {
      height: 1.5rem;
      width: 1.5rem;
    }
    &__text {
      color: $color-secondary;
      font-size: .75rem;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.tooltip {
  padding: $spacing-xs $spacing-m !important;
  display: block;
  z-index: 1;
  background-color: $background-grey;
  box-shadow: $box-shadow;
  border: 1px solid $light-grey;
  @include border-style();
}



.image-loader {
  position: absolute;
  top: 0 ;
  width: 100%;
  height: 100%;
  background: $light-grey;
  z-index: 1;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
  @include border-style();
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -20%;
    width: 15%;
    height: 100%;
    transform: skewX(-20deg);
    background: lighten($light-grey, 1%);
    z-index: 1;
    animation: left-to-right 1.5s infinite ease-in-out;
  }
}

@keyframes left-to-right {
  0% {
    left: -30%;
    width: 5%;
  }

  60% {
    left: 30%;
    width: 20%;
  }

  100% {
    left: 130%;
    width: 10%;
  }
}


blockquote {
  padding: calc($spacing-xs / 2) calc($spacing-xs);
  margin: $spacing-xs 0;
  background: $light-grey;
  border-left: 4px solid $color-secondary;
  @include border-style();
  color: $color-primary;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  @include inter-light;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

code {
  background: $light-grey;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @include inter-light;
}


.text-container {

  max-width: 1032px;
  margin: $header-height auto 0 auto;
  padding: 64px $spacing-l 64px $spacing-l;
  min-height: calc(100% - 72px);
  box-sizing: border-box;

  p{
    font-size: 16px;
  }

  @include breakpoint('m', 'max') {
    padding: 48px $spacing-m 48px $spacing-m;
    margin: $header-height-mobile auto $header-height-mobile auto;
    height: auto;
  }}
