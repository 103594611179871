h1, .h1{
  @include inter-medium;
  color: $primary;
  font-size: 1.5rem;
  line-height: 133.333%;
  margin: 0;

  .subline {
    margin-top: $spacing-s;
    font-size: .875rem;
  }

  &--strong {
    @include inter-bold;
  }
}

h2, .h2 {
  @include inter-medium;
  color: $primary;
  font-size: 1.125rem;
  line-height: 144.444%;
  margin: 0;
}

h3, .h3 {
  @include inter-medium;
  color: $primary;
  font-size: .875rem;
  line-height: 142.857%;
  margin: 0;
}

.subline {
  @include inter-regular;
  font-size: .75rem;
  line-height: 133.333%;
  color: $secondary;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
  &:active, &:hover, &:focus {
    color: inherit;
    text-decoration: none;
  }
}
