@import "fonts";

//colors
$color-primary: #272E35;
$color-secondary: #555F6D;
$color-tertiary: #7E8C9A;
$grey-content-tetriary: #7E8B99;

$primary: $color-primary !default;
$secondary: $color-secondary !default;
$tertiary: $color-tertiary !default;

$primary-background: #02034F;
$primary-blue: #353572;
$blue-hover: #252668;
$blue-active: #1B1C61;
$blue-background: #44457D;
$soft-blue: #3062D4;
$blue-outline: #8DB0FB;
$blue-outline-action-hovered: #A0B7EB;
$blue-outline-action-selected: #7B9BE3;
$blue-action-enabled: #2759CD;
$soft-green: #4AA578;
$danger: #A13636;
$danger-hover: #7a2e2e;
$red-action-enabled: #B12F2F;
$red-outline-action-pressed: #F26464;
$red-background-subtle: #FEE6E6;
$red-content-primary: #6F2020;
$red-border-medium: #FCCFCF;
$red-background-subtle: #FEF5F5;

$background-grey: #FCFCFD;
$light-grey: #EAEDF0;
$medium-grey: #CFD6DD;
$dark-grey: #1B242C;
$action-grey: #4A545E;
$action-selected: #E8E8EF;
$action-hovered: #9A9AB9;

$button-bg-hover: #F0F3F5;
$button-bg-active: #7E8C9A;
$button-border-hover: #9EA8B3;

$post-it-red: #DC6B6B;
$post-it-green: #B9D745;
$post-it-blue: #45A1E3;
$post-it-purple: #A822E6;

// spacings
$spacing-xs: .375rem;
$spacing-s: .5rem;
$spacing-sm: .625rem;
$spacing-sl: .75rem;
$spacing-m: 1rem;
$spacing-l: 1.5rem;
$spacing-xl: 2rem;
$spacing-xxl: 3rem;

//misc
$border-radius-xs: .25rem;
$border-radius-s: .5rem;
$border-radius-m: .75rem;
$border-radius-l: 1rem;
$border-radius-xl: 1.5rem;
$box-shadow:  0 2px 2px -1px rgba(2, 47, 82, 0.06);
$box-shadow-l: 0 16px 24px -6px rgba(4, 41, 68, 0.16), 0 2px 2px -1px rgba(0, 43, 94, 0.04);

// component specifics
$header-height: 72px;
$header-height-mobile: 56px;


$max-width: 1200px;

//mixins

// mixin for border styles
@mixin border-style() {
  border-radius: $border-radius-s;
  border: 1px solid $light-grey;
}

//mixin for icon sizes

@mixin icon-size() {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

// mixin for easy breakpoints include
$breakpoints: (
        'default':  '',
        's':  380px,
        'sm': 768px,
        'm':  995px,
        'l':  1440px,
        'challenge': 1810px
);

// mixin for breakpoints (min-width for mobile first)
@mixin breakpoint($breakpoint,$direction: 'min') {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
  @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}

//global styles
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  color: $primary;
  @include inter-regular;
  font-size: 1rem;
  line-height: 150%;
  margin: 0;
  height: 100vh;
}

@import "type";
